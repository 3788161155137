import { extendTheme } from '@chakra-ui/react'
import "@fontsource/plus-jakarta-sans/800.css"
import "@fontsource/plus-jakarta-sans/400.css"

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  fonts: {
    heading: `'Plus Jakarta Sans', sans-serif`,
    body: `'Plus Jakarta Sans', sans-serif`,
  },
})

export default theme
import { useState, useRef, useEffect } from "react";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

import {
  MagickFormat,
  ResolutionType,
} from "@phonedolly/magick-wasm";

import JSZip from "jszip";
import getDate from "./utils/getDate";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Button, ButtonGroup, Checkbox, Code, Heading, Highlight, HStack, IconButton, Input, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Select, Stack, useDisclosure, Text, VStack, useToast, Tooltip
} from '@chakra-ui/react'

import { CheckIcon, DeleteIcon, SettingsIcon, WarningTwoIcon } from '@chakra-ui/icons'

// import "./App.css";
// import AppStyles from "./App.module.scss"

import { AnimatePresence, motion, useAnimationControls } from "framer-motion";

import NotOaAServer from "./NotOnAServer";

import fileExtension from "./utils/fileExtensionExtractor";
import Header from "./Header";
import sleep from './utils/sleep'
import fileNameShortener from "./utils/fileNameShortener";

const createWorker = createWorkerFactory(() => import("./utils/worker"));

function App() {
  const heights = { "0.5K": 360, "1K": 540, "2K": 1080, "4K": 2160, "8K": 4320 }
  const stateText = ["Converting...", "Complete!"];
  const outputFormatList = Object.values(MagickFormat);

  const [showStateText, setShowStateText] = useState(false);
  const [progressState, setProgressState] = useState("");
  const [isOperating, setIsOperating] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [manageTarget, setManageTarget] = useState(0);
  const [convertButtonStick, setConvertButtonStick] = useState(false);
  const [showResolutionHelp, setShowResolutionHelp] = useState(false);
  const [firstFileConverted, setFirstFileConverted] = useState(false);
  const [downloadAsZip, setDownloadAsZip] = useState(false);

  const worker = useWorker(createWorker);
  const inputFile = useRef();
  const dropZoneAnimationControls = useAnimationControls();
  const convertButtonAnimationControls = useAnimationControls();
  const restartButtonAnimationControls = useAnimationControls();
  const downloadAsZipAnimationControls = useAnimationControls();

  const convertButtonRef = useRef();
  const appRef = useRef();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cyan400 = "#0BC5EA"
  const blackAlpha300 = "rgba(0, 0, 0, 0.16)"

  /* DropZone Initial Animation */
  useEffect(() => {
    dropZoneAnimationControls.start(() => ({
      scale: 1.0,
      opacity: 1,
      transition: { duration: 0.5, delay: 0.5, type: "spring" }
    }))
  }, [isOperating, dropZoneAnimationControls])

  /* Convert Button Initial Animation */
  useEffect(() => {
    if (imageList.length > 0 && convertButtonRef.current != null) {

      convertButtonAnimationControls.start(() => ({
        scale: 1.0, opacity: 1
      }))
    }
  }, [imageList.length, convertButtonAnimationControls, convertButtonStick])

  /* Restart Button Initial Animation */
  useEffect(() => {
    if (isOperating === true && firstFileConverted === true) {
      restartButtonAnimationControls.start(() => ({
        scale: 1.0, opacity: 1
      }))
    }
  }, [firstFileConverted, isOperating, restartButtonAnimationControls])

  /* DownloadAsZip Button Initial Animation */
  useEffect(() => {
    if (imageList.length > 0 && downloadAsZip === false) {
      downloadAsZipAnimationControls.start(() => ({
        scale: 1.0,
        opacity: 1,
        backgroundColor: downloadAsZip === true ? cyan400 : blackAlpha300,
        transition: { duration: 0.5, type: "spring" }
      }))
    }
  }, [downloadAsZipAnimationControls, imageList, downloadAsZip])

  /* add ConvertButtonIsShow Listener */
  useEffect(() => {
    window.addEventListener('scroll', setConvertButtonMode);
  });

  const setConvertButtonMode = (e) => {
    if (!convertButtonRef.current) {
      return;
    }
    const y = convertButtonRef.current.getBoundingClientRect().y;
    if ((y <= window.innerHeight * 0.05) && (convertButtonStick === false)) {
      setConvertButtonStick(() => true);
      convertButtonAnimationControls.start(() => ({
        scale: 1.15
      }))
    }
    else if ((y > window.innerHeight * 0.05)) {
      setConvertButtonStick(() => false);
      convertButtonAnimationControls.start(() => ({
        scale: 1.0
      }))
    }
  }
  // useEffect(() => {
  //   console.log(imageList)
  // }, [imageList])

  const formats = Object.values(MagickFormat);
  const onButtonClick = () => {
    if (showStateText || isOperating) {
      return;
    }
    // `current` points to the mounted file Input element
    inputFile.current.click();
  };

  const handleFileInput = async (event) => {
    if (event.target.value.length === 0) {
      return;
    }

    [...event.target.files].forEach(eachFile => {
      setImageList(prev =>
        prev.concat({
          id: uuidv4(),
          context: eachFile,
          name: eachFile.name,
          inputFormat: fileExtension(eachFile.name).toUpperCase(),
          outputFormat: MagickFormat.Png,
          resizeWidth: undefined,
          resizeHeight: undefined,
          outputHeight: heights["4K"],
          unitType: ResolutionType.PixelsPerInchResolution,
          compressionQuality: 85,
          processed: false,
          processSuccess: true,
          processFailReason: undefined
        })
      )
    })
  }

  const dropHandler = (e) => {
    e.preventDefault();
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
    [...e.dataTransfer.items].forEach((item, i) => {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        let isValidExt = false;
        outputFormatList.forEach(eachFormat => {
          if (eachFormat.toLowerCase() === fileExtension(file.name).toLowerCase()) {
            isValidExt = true;
          }
        })

        if (isValidExt === false) {
          return;
        }
        setImageList(prev =>
          prev.concat({
            id: uuidv4(),
            context: file,
            name: file.name,
            inputFormat: fileExtension(file.name).toUpperCase(),
            outputFormat: MagickFormat.Png,
            resizeWidth: undefined,
            resizeHeight: undefined,
            outputHeight: heights["4K"],
            unitType: ResolutionType.PixelsPerInchResolution,
            compressionQuality: 85,
            processed: false,
            processSuccess: true,
            processFailReason: undefined
          })
        )
      }
    })
  }

  const convertButtonHandler = async () => {
    const promises = [];

    window.scroll({ top: 0, behavior: "smooth" })
    dropZoneAnimationControls.start(() => ({
      scale: 0
    }))
    setProgressState("Converting...");


    setIsOperating(() => true);
    await sleep(300);
    imageList.forEach(eachImage => {
      const fileRead = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          promises.push(
            worker.convertAndSave(JSON.stringify(
              {
                image: {
                  'lastModified': eachImage.context.lastModified,
                  'lastModifiedDate': eachImage.context.lastModifiedDate,
                  'name': eachImage.context.name,
                  'size': eachImage.context.size,
                  'type': eachImage.context.type,
                  'dataURI': reader.result
                },
                outputConfig: {
                  resizeWidth: eachImage.resizeWidth !== 0 && eachImage.resizeWidth !== "" ? eachImage.resizeWidth : undefined,
                  resizeHeight: eachImage.resizeHeight !== 0 && eachImage.resizeHeight !== "" ? eachImage.resizeHeight : undefined,
                  outputHeight: eachImage.outputHeight !== 0 && eachImage.outputHeight !== "" ? eachImage.outputHeight : undefined,
                  compressionQuality: eachImage.compressionQuality !== 0 && eachImage.compressionQuality !== "" ? eachImage.compressionQuality : undefined,
                  resolution: eachImage.resolution !== 0 && eachImage.resolution !== "" ? String(eachImage.resolution) : undefined,
                  outputFormat: eachImage.outputFormat,
                }
              })).then((convertResult) => {
                setImageList(prev => {
                  eachImage.processed = true;
                  eachImage.processSuccess = true;
                  return prev.slice();
                })
                setFirstFileConverted(() => true);
                resolve({
                  name: eachImage.name,
                  convertData: convertResult,
                  inputFormat: eachImage.inputFormat,
                  outputFormat: eachImage.outputFormat
                })
              }
              ).catch(error => {
                setImageList(prev => {
                  eachImage.processed = true;
                  eachImage.processSuccess = false;
                  return prev.slice();
                })
                setFirstFileConverted(() => true);
                console.log('error in convertHandler');
                console.error(error);
                console.log(error.message);
                console.log(error.name);
                console.log()
                reject(error);
              })
          )
        }
        reader.readAsDataURL(eachImage.context);
      })
      promises.push(fileRead);
    })

    Promise.allSettled(promises).then(result => {
      console.log(result);
      if (downloadAsZip === true) {
        const zip = JSZip();

        if (imageList.length === 1 && result[0].value.inputFormat.toLowerCase() === "pdf") {
          saveAs(result[0].value.convertData, `${result[0].value.name.substr(0, result[0].value.name.lastIndexOf("."))}.pdf.zip`)
        } else {
          result.forEach(eachResult => {
            const eachImage = eachResult.value;
            if (eachImage.inputFormat.toLowerCase() === "pdf") {
              zip.file(`${eachImage.name.substr(0, eachImage.name.lastIndexOf("."))}.zip`, eachImage.convertData)
            }
            else {
              zip.file(`${eachImage.name.substr(0, eachImage.name.lastIndexOf("."))}.${eachImage.outputFormat.toLowerCase()}`, eachImage.convertData)
            }

          })
          zip.generateAsync({ type: "blob" })
            .then(zipResult => {
              saveAs(zipResult, `${new Date()}.zip`)
            })
        }

      }
      else {
        result.forEach(eachResult => {
          const eachImage = eachResult.value;

          saveAs(eachImage.convertData, `${eachImage.name.substr(0, eachImage.name.lastIndexOf("."))}.${eachImage.inputFormat.toLowerCase() === "pdf" ? "zip" : eachImage.outputFormat.toLowerCase()}`);
        })
      }
      setProgressState("Complete!");
    })

  }


  const dragOverHandler = (e) => {
    e.preventDefault();
    dropZoneAnimationControls.start(() => ({
      scale: 1.15,
      color: "rgba(0,0,0,1)",
    }))
    dropZoneAnimationControls.start(() => ({
      backgroundColor: "rgba(200,200,200,1)",
      transition: { duration: 0.08 }
    }))
  }

  const dragOverLeaveHandler = (e) => {
    e.preventDefault();
    dropZoneAnimationControls.start(() => ({
      scale: 1.0,
      color: "rgba(255, 255, 255, 0.92)",
    }))
    dropZoneAnimationControls.start(() => ({
      backgroundColor: "rgba(255,255,255, 0.08)",
      transition: { duration: 0.08 },
    }))
  }

  const dropZoneHoverStartHandler = (e) => {
    e.preventDefault();
    dropZoneAnimationControls.start(() => ({
      scale: 1.1
    }))
  }
  const dropZoneHoverEndHandler = () => {
    // e.preventDefault();
    dropZoneAnimationControls.start(() => ({
      scale: 1.0,
      color: "rgba(255, 255, 255, 0.92)",
      backgroundColor: "rgba(255,255,255, 0.08)",
    }))
  }

  const convertButtonHoverStartHandler = (e) => {
    e.preventDefault();

    convertButtonAnimationControls.start(() => ({
      scale: 1.1
    }))

  }
  const convertButtonHoverEndHandler = (e) => {
    e.preventDefault();
    convertButtonAnimationControls.start(() => ({
      scale: 1.0
    }))
  }


  const restartButtonHoverStartHandler = (e) => {
    e.preventDefault();
    restartButtonAnimationControls.start(() => ({
      scale: 1.1
    }))
  }
  const restartButtonHoverEndHandler = (e) => {
    e.preventDefault();
    restartButtonAnimationControls.start(() => ({
      scale: 1.0
    }))
  }

  const downlaodAsZipButtonHoverStartHandler = (e) => {
    e.preventDefault();
    downloadAsZipAnimationControls.start(() => ({
      scale: 1.1
    }))
  }
  const downlaodAsZipButtonHoverEndHandler = (e) => {
    e.preventDefault();
    downloadAsZipAnimationControls.start(() => ({
      scale: 1.0
    }))
  }

  const getManageTarget = (id) => {
    let target;

    imageList.forEach((eachImage, i) => {
      if (id === eachImage.id) {
        target = i;
      }
    })
    return target;
  }

  return (
    <Box className="App"
      m="0 10vw">
      <VStack pt="10vh" pb="2.5em" spacing="2.5em" >
        <Header />
        <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onInput={handleFileInput} multiple />
        <AnimatePresence>
          {isOperating === false ?
            <Box
              as={motion.div}
              initial={{ scale: 0.5, opacity: 0, backgroundColor: "rgba(255,255,255, 0.08)" }}
              animate={dropZoneAnimationControls}
              layout
              onHoverStart={dropZoneHoverStartHandler}
              onHoverEnd={dropZoneHoverEndHandler}
              // exit={imageList?.length > 0 ? { scale: 0.5, opacity: 0 } : {}}
              // whileHover={{ scale: 1.06 }}
              onClick={onButtonClick}
              cursor="pointer"
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
              onDragLeave={dragOverLeaveHandler}
              px="2em"
              display="flex" justifyContent="center" alignItems="center" color="white"
              w={{ base: "70vw", sm: "60vw", lg: "40em", xl: "50em" }}
              h={{ base: "10em", sm: "16em", lg: "16em", xl: "20em" }}
              borderRadius="50px"
              boxShadow="dark-lg"
            >
              <Heading size={{ base: "2xl", md: "3xl" }} fontWeight="800" textAlign="center">
                Tap Or Drop!
              </Heading>
            </Box>
            : null
          }
        </AnimatePresence>
        <AnimatePresence>
          {isOperating === true ?
            <VStack>
              <Box
                as={motion.div}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1.0, opacity: 1, backgroundColor: "rgba(255, 255, 255, 0.06)" }}
                exit={{ scale: 0.5, opacity: 0 }}
                layout
                whileHover={{ scale: 1.1 }}
                cursor="pointer"
                px="2em" display="flex" justifyContent="center" alignItems="center" color="white"
                w={{ base: "80vw", sm: "60vw", lg: "50vw", xl: "35em" }}
                h={{ base: "10em", sm: "12em", xl: "15em" }}
                borderRadius="30px"
                boxShadow="dark-lg"
              >
                <Heading size="2xl">
                  {progressState}
                </Heading>
              </Box>
            </VStack>
            : null
          }
        </AnimatePresence>
        <AnimatePresence>
          {((imageList.length > 0) && (isOperating === false)) ?
            <Box
              as={motion.div}
              initial={{ scale: 0.5, opacity: 0, borderRadius: 9999 }}
              animate={convertButtonAnimationControls}
              // exit={{ scale: 0.5, opacity: 0, borderRadius: 9999 }}
              onHoverStart={convertButtonHoverStartHandler}
              onHoverEnd={convertButtonHoverEndHandler}
              layout
              cursor="pointer"
              bg="cyan.400" color="white"
              w={{ base: "11em", md:"14em", xl: "18em" }}
              h={{ base: "3.5em", md:"4em", xl: "5em" }}
              display="flex" alignItems="center" justifyContent="center"
              borderRadius="full"
              position="sticky"
              top={window.innerHeight * 0.04}
              zIndex="999"
              ref={convertButtonRef}
              onClick={convertButtonHandler}
              boxShadow="dark-lg"
            >
              <Heading size={{ base: "xl", xl: "2xl" }} as={motion.h1}>Convert!</Heading>
            </Box>
            : null
          }
          {(isOperating === true) && (firstFileConverted === true) ?
            <Box
              as={motion.button}
              // initial={{ scale: 0.5, opacity: 0 }}
              animate={restartButtonAnimationControls}
              // exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.10 } }}
              onHoverStart={restartButtonHoverStartHandler}
              onHoverEnd={restartButtonHoverEndHandler}
              bg="cyan.400"
              p="0.4em 0.7em"
              w={{ base: "11em", xl: "12em" }}
              h="3.5em"
              borderRadius="full"
              key={uuidv4()}
              boxShadow="dark-lg"
              onClick={async () => {
                setFirstFileConverted(() => false);
                setImageList((prev) => []);
                setIsOperating(() => false);
              }}>
              <Heading size="xl">Restart!</Heading>
            </Box>
            : null
          }
        </AnimatePresence>
        <VStack spacing="0.75em">
          <AnimatePresence>
            {isOperating === false && imageList.filter(eachImage => eachImage.processed === false).map((eachImage) => {
              const target = getManageTarget(eachImage.id);
              return (
                <Box
                  as={motion.div}
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.7, opacity: 0, transition: { duration: 0.2 } }}
                  key={eachImage.id}
                  layout
                  bg="blackAlpha.300"
                  p="3"
                  w={{ base: "70vw", sm: "60vw", lg: "40em", xl: "50em" }}
                  borderRadius="2xl"
                  shadow="lg">
                  <Stack justifyContent="space-between" direction={{ base: "column", md: "row" }} spacing="2.5" alignItems="center">
                    <Heading textOverflow="ellipsis" size={{ base: "xs", md: "sm" }}>{eachImage.name.length > 20 ? fileNameShortener(eachImage.name, 5, '...') : eachImage.name}</Heading>
                    <HStack justifyContent="space-between">
                      <HStack justify="center">
                        <Heading flexBasis={{ base: null, md: "10em" }} size={{ base: "xs" }} color="cyan.300">Output<br />Format:</Heading  >
                        <Select
                          size={{ base: "xs", sm: "sm", md: "md" }}
                          onChange={(e) => {
                            const nextImageListState = imageList.map((image, i) => {
                              if (i === manageTarget) {
                                image.outputFormat = e.target.value.toUpperCase();
                              }
                              return image;
                            })
                            setImageList(nextImageListState);
                          }}
                          value={imageList[target]?.outputFormat}
                          disabled={isOperating ? true : false}>
                          {formats.map((eachFormat) =>
                            <option key={uuidv4()} value={eachFormat}>
                              {eachFormat}
                            </option>
                          )}
                        </Select>
                      </HStack>
                        <IconButton aria-label="Open Image Convert Settings" key={uuidv4()} icon={<SettingsIcon />}
                          size={{ base: "sm", md: "md" }}
                          onClick={() => {
                            const target = getManageTarget(eachImage.id);
                            setManageTarget(() => target);
                            onOpen();
                          }} />
                        <IconButton aria-label="Delete Image" key={uuidv4()} icon={<DeleteIcon />}
                          size={{ base: "sm", md: "md" }}
                          onClick={() => {
                            setImageList(prev => {
                              const target = getManageTarget(eachImage.id);
                              return prev.filter((eachImage, i) => i !== target)
                            })
                          }} />
                    </HStack>
                  </Stack>
                </Box>)
            }
            )}
            {/* Convert Success List */}
            {imageList.filter(eachImage => eachImage.processed === true && eachImage.processSuccess === true).map((eachImage) =>
              <Box
                as={motion.div}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.7, opacity: 0, transition: { duration: 0.2 } }}
                key={eachImage.id}
                layout
                bg="green.400"
                p="0.4em 0.7em"
                w={{ base: "70vw", sm: "60vw", lg: "40em", xl: "50em" }}
                h="3em"
                display="flex" alignContent="center"
                borderRadius="2xl"
                shadow="lg">
                <HStack justifyContent="left" columnGap="0.3em">
                  <CheckIcon />
                  <Heading size={{ base: "xs", md: "md" }} textOverflow="ellipsis">{eachImage.name.length > 20 ? fileNameShortener(eachImage.name, 5, '...') : eachImage.name}</Heading>
                </HStack>
              </Box>
            )}
            {/* Convert Fail List */}
            {imageList.filter(eachImage => eachImage.processed === true && eachImage.processSuccess === false).map((eachImage) =>
              <Box
                as={motion.div}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.5, opacity: 0 }}
                key={eachImage.id}
                layout
                bg="red.400"
                p="0.4em 0.7em"
                w={{ base: "70vw", sm: "60vw", lg: "40em", xl: "50em" }}
                display="flex" alignContent="center"
                borderRadius="2xl"
                shadow="lg">
                <HStack display="flex" justifyContent="left" columnGap="0.3em">
                  <WarningTwoIcon />
                  <Heading size="md" textOverflow="ellipsis">{eachImage.name}</Heading>
                </HStack>
              </Box>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {imageList.length > 0 && isOperating === false
              ?
              <Box
                as={motion.button}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.5, opacity: 0 }}
                whileHover={{ scale: 1.1 }}
                bg="red.500"
                p="0.4em 0.7em"
                w={{ base: "70vw", sm: "60vw", lg: "40em", xl: "50em" }}
                h="3em"
                borderRadius="2xl"
                onClick={() => {
                  setImageList((prev) => [])
                }}>
                <Heading size="md">Delete All</Heading>
              </Box>
              :
              null
            }
          </AnimatePresence>
          <AnimatePresence>
            {imageList.length > 0 && isOperating === false
              ?
              <Box
                as={motion.button}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={downloadAsZipAnimationControls}
                exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.10 } }}
                // whileHover={{ scale: 1.1 }}
                onHoverStart={downlaodAsZipButtonHoverStartHandler}
                onHoverEnd={downlaodAsZipButtonHoverEndHandler}
                // bg={downloadAsZip === true ? "cyan.400" : "blackAlpha.300"}
                p="0.4em 0.7em"
                w={{ base: "70vw", sm: "60vw", lg: "40em", xl: "50em" }}
                h="3em"
                borderRadius="2xl"
                onClick={() => {
                  if (downloadAsZip === true) {
                    // disable color
                    downloadAsZipAnimationControls.start(() => ({
                      backgroundColor: blackAlpha300,
                      transition: { duration: 0.2 }
                    }))
                  } else {
                    downloadAsZipAnimationControls.start(() => ({
                      backgroundColor: cyan400,
                      transition: { duration: 0.2 }
                    }))
                  }
                  setDownloadAsZip(prev => !prev)

                }}>
                <Heading size="md">Download All Files As ZIP</Heading>
              </Box>
              :
              null
            }
          </AnimatePresence>
          <NotOaAServer key={uuidv4()} />
        </VStack>
      </VStack >
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "3xl" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Convert Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing="2em">
              <Box display="flex" justifyContent="space-between" w="100%" alignItems="center" key={uuidv4()}>
                <Text flexBasis="10em">Output Format: </Text>
                <Select onChange={(e) => {
                  const nextImageListState = imageList.map((image, i) => {
                    if (i === manageTarget) {
                      image.outputFormat = e.target.value.toUpperCase();
                    }
                    return image;
                  })
                  setImageList(nextImageListState);
                }}
                  value={imageList[manageTarget]?.outputFormat}
                  disabled={isOperating ? true : false}>
                  {formats.map((eachFormat) =>
                    <option key={uuidv4()} value={eachFormat}>
                      {eachFormat}
                    </option>
                  )}
                </Select>
              </Box>
              <Box display="flex" flexDir="row" justifyContent="space-between" w="100%" alignItems="center" key={uuidv4()}>
                <Text size={{ base: "sm", sm: "md" }} pr="1em">
                  Commonly Used<br/>Formats As Output:
                </Text>
                <Stack direction={{ base: "column", sm: "row" }} flexGrow={3} justifyContent="space-between" columnGap={{ base: "0.5em", md: "1em" }}>
                  <Button
                    fontSize={{ base: "xs", sm: "sm", md: "md" }}
                    w="100%"
                    onClick={() => setImageList((prev) => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].outputFormat = MagickFormat.Png
                      return newImageList;
                    })} disabled={isOperating ? true : false}>
                    PNG
                  </Button>
                  <Button
                    fontSize={{ base: "xs", sm: "sm", md: "md" }}
                    w="100%"
                    onClick={() => setImageList((prev) => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].outputFormat = MagickFormat.Jpg
                      return newImageList;
                    })} disabled={isOperating ? true : false}>
                    JPG(JPEG)
                  </Button>
                  <Button
                    fontSize={{ base: "xs", sm: "sm", md: "md" }}
                    w="100%"
                    onClick={() => setImageList((prev) => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].outputFormat = MagickFormat.Pdf
                      return newImageList;
                    })} disabled={isOperating ? true : false}>
                    PDF
                  </Button>
                </Stack>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" columnGap="0.5em" w="100%" key={uuidv4()}>
                <Text flexGrow={1}>Height of Generated Output Image(px): </Text>
                <Stack direction={{ base: "column", sm: "row" }} flexGrow={3} justifyContent="space-between" columnGap="0em">
                  <Input type="number" min="0" value={imageList[manageTarget]?.outputHeight}
                    disabled={imageList.length > 0 && (imageList[manageTarget].inputFormat.toLowerCase() !== "pdf" && imageList[manageTarget].inputFormat.toLowerCase() !== "svg")}
                    onChange={(e) => {
                      setImageList(prev => {
                        const newImageList = prev.slice();
                        newImageList[manageTarget].outputHeight = Number(e.target.value);
                        return newImageList;
                      })
                    }} />
                  <Select
                    disabled={imageList.length > 0 && (imageList[manageTarget].inputFormat.toLowerCase() !== "pdf" && imageList[manageTarget].inputFormat.toLowerCase() !== "svg")}
                    onChange={(e) => {
                      setImageList(prev => {
                        const newImageList = prev.slice();
                        newImageList[manageTarget].outputHeight = Number(e.target.value);
                        return newImageList;
                      })
                    }}
                    value={imageList[manageTarget]?.outputHeight}>
                    {["0.5K", "1K", "2K", "4K", "8K"].map((eachResolution) =>
                      <option key={uuidv4()} value={heights[`${eachResolution}`]}>
                        {eachResolution}
                      </option>
                    )}
                  </Select>
                  <Button
                    fontSize={{ base: "xs", sm: "sm", md: "md" }}
                    onClick={() => setImageList(prev => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].outputHeight = heights["4K"];
                      return newImageList;
                    })}>Reset</Button>
                </Stack>
              </Box >
              {/* <div className={AppStyles.specificOption}> */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" columnGap="0.5em" w="100%" key={uuidv4()}>
                <Text flexGrow={2}>Output Image Quality(1~100): </Text>
                <Input type="number"
                  width="10em"
                  disabled={isOperating ? true : false}
                  onChange={(event) => {
                    setImageList(prev => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].compressionQuality = Number(event.target.value);
                      return newImageList;
                    })
                  }}
                  min={0}
                  max={100}
                  defaultValue={imageList[manageTarget]?.compressionQuality === 0 ? "" : imageList[manageTarget]?.compressionQuality}
                  placeholder="85"
                />
                <Button
                  fontSize={{ base: "xs", sm: "sm", md: "md" }}
                  onClick={() => setImageList(prev => {
                    const newImageList = prev.slice();
                    newImageList[manageTarget].compressionQuality = 85;
                    return newImageList;
                  })}>Reset</Button>
                {/* </div> */}
              </Box>
              {/* <div className={AppStyles.specificOption} key={uuidv4()}> */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" columnGap="0.5em" w="100%" key={uuidv4()}>
                <p>Resized Final Output Image Width(px): </p>
                <Input type="number"
                  disabled={isOperating ? true : false}
                  onChange={(e) => {
                    setImageList(prev => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].resizeWidth = e.target.value;
                      return newImageList;
                    })
                  }}
                  min={0}
                  defaultValue={imageList[manageTarget]?.resizeWidth === 0 ? "" : imageList[manageTarget]?.resizeWidth}
                />
                <Button
                  fontSize={{ base: "xs", sm: "sm", md: "md" }}
                  onClick={() => setImageList(prev => {
                    const newImageList = prev.slice();
                    newImageList[manageTarget].resizeWidth = 0;
                    return newImageList;
                  })}>Reset</Button>
                {/* </div> */}
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" columnGap="0.5em" w="100%" key={uuidv4()}>
                <p>Resized Final Output Image Height(px): </p>
                <Input type="number"
                  disabled={isOperating ? true : false}
                  onChange={(e) => {
                    setImageList(prev => {
                      const newImageList = prev.slice();
                      newImageList[manageTarget].resizeHeight = e.target.value;
                      return newImageList;
                    })
                  }}
                  min={0}
                  value={imageList[manageTarget]?.resizeHeight === 0 ? "" : imageList[manageTarget]?.resizeHeight}
                />
                <Button
                  fontSize={{ base: "xs", sm: "sm", md: "md" }}
                  onClick={() => setImageList(prev => {
                    const newImageList = prev.slice();
                    newImageList[manageTarget].resizeHeight = 0
                    return imageList;
                  })}>Reset</Button>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" columnGap="0.5em" w="100%" key={uuidv4()}>
                <p>
                  {/* <strong className={AppStyles.experimental}> */}
                  {`Experimental! `}
                  {/* </strong> */}
                </p>
                <Select onChange={(e) => {
                  setImageList(prev => {
                    const newImageList = prev.slice();
                    if (Number(e.target.value) === ResolutionType.PixelsPerInchResolution) {
                      newImageList[manageTarget].unitType = ResolutionType.PixelsPerInchResolution;
                    }
                    else if (Number(e.target.value) === ResolutionType.PixelsPerCentimeterResolution) {
                      newImageList[manageTarget].unitType = ResolutionType.PixelsPerCentimeterResolution;;
                    }
                    return newImageList;
                  })

                }}
                  defaultValue={imageList[manageTarget]?.unitType}
                  disabled={isOperating ? true : false}>
                  <option key={uuidv4()} value={ResolutionType.PixelsPerInchResolution}>
                    PPI(Pixels Per Inch)
                  </option>
                  <option key={uuidv4()} value={ResolutionType.PixelsPerCentimeterResolution}>
                    PPC(Pixels Per Centimeter)
                  </option>
                </Select>
                <Input type="text"
                  disabled={isOperating ? true : false}
                  onChange={(e) => setImageList(prev => {
                    const newImageList = prev.slice();
                    newImageList[manageTarget].resolution = e.target.value; // density as string
                    return newImageList;
                  })
                  }
                  min={0}
                  defaultValue={imageList[manageTarget]?.resolution === 0 ? "" : imageList[manageTarget]?.resolution}
                  placeholder={imageList[manageTarget]?.unitType === ResolutionType.PixelsPerInchResolution ? "Input PPI" : "Input PPC"}
                />
                <Button
                  fontSize={{ base: "xs", sm: "sm", md: "md" }}
                  onClick={() => setImageList(prev => {
                    const newImageList = prev.slice();
                    newImageList[manageTarget].resolution = undefined;
                    return newImageList;
                  })}>Reset</Button>
                <Button
                  fontSize={{ base: "xs", sm: "sm", md: "md" }}
                  onClick={() => setShowResolutionHelp(!showResolutionHelp)}>Help</Button>
              </Box>
              {showResolutionHelp
                ?
                <Box>
                  <Heading size="md">Select One Option Type</Heading>
                  <Code >density</Code>
                  <Text size="sm">or</Text>
                  <Text><Code >pixelDensityX</Code><i style={{ color: "#0BC5EA" }}>x</i><Code >pixelDensityY</Code></Text>
                </Box>
                : null}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack >
              <Button variant='solid'
                onClick={() => {
                  setImageList(prev => {
                    const newImageList = prev.slice();
                    newImageList.forEach((eachImage, i) => {
                      if (i === manageTarget) {
                        return;
                      }
                      const id = newImageList[i].id;
                      newImageList[i] = { ...newImageList[manageTarget], id }
                    })
                    return newImageList;
                  })
                  toast({
                    title: "Success!",
                    description: "Successfully Applied To All Images.",
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                  })
                }}
              >
                {window.innerWidth < 480 ?
                  <Text>Apply To<br />All Other Images</Text>
                  : `Apply to All Other Images`
                }
              </Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Apply
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box >
  );
}

export default App;

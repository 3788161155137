
import {
  Link as RouterDomLink
} from 'react-router-dom'

import { Heading, Highlight, Link, VStack } from "@chakra-ui/react"

export default function NotOaAServer() {
  return (
    <VStack>
      <Heading color="#61dafb" size={{ base: "md", md: "xl" }}>
        Thanks for Use!
      </Heading>
      <Heading lineHeight="taller" size={{ base: "sm", md: "lg" }} textAlign="center">
        This site converts Images on YOUR MACHINE,
        <br/>
        not on a SERVER!
      </Heading>
      <Link as={RouterDomLink} to="/license">(c) 2022, img2img</Link>
    </VStack>
  )
}
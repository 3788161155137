import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { ChakraProvider } from '@chakra-ui/react'
import theme from './Theme'
import License from './License';

const router = createBrowserRouter([
  {
    path: "/",
    element: <ChakraProvider theme={theme}><App />  </ChakraProvider>,
  },
  {
    path: "license",
    element: <License />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <RouterProvider router={router} />


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
